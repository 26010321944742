import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  Redirect,
  Link,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
import {
  Row,
  Col,
  Menu,
  Button,
  Modal,
  message,
  Form,
  Cascader,
  Select,
  Switch,
  Input,
  InputNumber,
} from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import { QRCodeCanvas } from "qrcode.react";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";

import {} from "../../../common/utils";

import { GET_CITY } from "../../../api/common";
import { GET_GOODS_DETAIL, CREATE_GOODS_ORDER } from "../../../api/goods";
import { GET_USER_USE_COUPON_LIST } from "../../../api/coupon";

import { GET_INFO } from "../../../api/login";
const Detail = (props) => {
  const {} = props;

  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [userInfo, setUserInfo] = useState({});

  const [detail, setDetail] = useState({});

  const [address, setAddress] = useState([]);

  const [sku, setSku] = useState([]);
  const [showSku, setShowSku] = useState([]);
  const [curSku, setCurSku] = useState([]);
  const [curProductSku, setCurProductSku] = useState({});

  const [couponList, setCouponList] = useState([]);
  const [chooseCoupon, setChooseCoupon] = useState(null);
  const [useIntegral, setUseIntegral] = useState(0);
  const [qrcodeUrl, setQrcodeUrl] = useState("");
  const [qrcodeVisible, setQrcodeVisible] = useState(false);
  const [subParams, setSubParams] = useState({});
  const [number, setNumber] = useState(1);

  const [toBuyVisible, setToBuyVisible] = useState(false);

  const [postagePrice, setPostagePrice] = useState(0);

  const [canToOrder, setCanToOrder] = useState(false);

  useEffect(() => {
    getDetail(params.id);
    getUserInfo();
  }, [params.id]);

  const getUserInfo = async () => {
    const {
      data: { data, code, msg },
    } = await GET_INFO();
    if (code == 200) {
      // console.log(res.data)
      setUserInfo(data);
      localStorage.setItem("userInfo", JSON.stringify(data));
    } else {
      cookie.remove("accessToken", { path: "/" });
      setUserInfo({});
      localStorage.removeItem("userInfo");
      msg && message.error(msg);
    }
  };

  const getDetail = async (id) => {
    const {
      data: { data, code, msg },
    } = await GET_GOODS_DETAIL({
      id,
    });
    if (code == 200) {
      console.log(data);
      setDetail(data);
      data.sku.map((item) => {
        // console.log(JSON.parse(item.sku));
        setSku([
          ...sku,
          {
            ...JSON.parse(item.sku),
          },
        ]);
      });
      // console.log(JSON.parse(data.sku_json));
      let skus = JSON.parse(data.sku_json);
      let showSkus = [];
      skus.map((item) => {
        for (const key in item) {
          const hasIndex = showSkus.findIndex((fitem) => fitem.label == key);
          if (hasIndex < 0) {
            showSkus.push({
              label: key,
              value: [],
            });
          }
        }
        Object.values(item).map((it, ind) => {
          const hasValue = showSkus[ind].value.findIndex(
            (vitem) => vitem == it
          );
          if (hasValue >= 0) {
            return;
          }
          showSkus[ind].value.push(it);
        });
      });
      setShowSku(showSkus);
    }
  };

  useEffect(() => {
    getProvince();
  }, []);

  const onAddressChange = async (val, sel) => {
    console.log(val, sel);
    if (sel) {
      sel.map((item, ind) => {
        if (ind == 0) {
          setSubParams({
            ...subParams,
            provinces_code: item.code,
            provinces_name: item.label,
          });
        }
        if (ind == 1) {
          setSubParams({
            ...subParams,
            cities_code: item.code,
            cities_name: item.label,
          });
        }
        if (ind == 2) {
          setSubParams({
            ...subParams,
            areas_code: item.code,
            areas_name: item.label,
          });
        }
      });
    } else {
      setSubParams({
        ...subParams,
        provinces_code: undefined,
        provinces_name: undefined,
        cities_code: undefined,
        cities_name: undefined,
        areas_code: undefined,
        areas_name: undefined,
      });
    }
  };

  const loadAddressData = async (selectedOptions) => {
    console.log(selectedOptions);
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    // load options lazily
    let arr = [];
    if (selectedOptions.length == 1) {
      arr = await getCity(selectedOptions[0].value);
      targetOption.children = [
        ...arr.map((item) => ({
          label: item.name,
          value: item.code,
          code: item.code,
          isLeaf: false,
          type: 2,
        })),
      ];
    } else if (selectedOptions.length == 2) {
      arr = await getArea(selectedOptions[1].value);
      targetOption.children = [
        ...arr.map((item) => ({
          label: item.name,
          value: item.code,
          code: item.code,
          type: 3,
        })),
      ];
    } else {
      return;
    }
    targetOption.loading = false;
    setAddress([...address]);
  };

  const getProvince = async () => {
    const {
      data: { data, code },
    } = await GET_CITY();
    if (code == 200) {
      setAddress([
        ...data.map((item) => ({
          label: item.name,
          value: item.code,
          code: item.code,
          isLeaf: false,
          type: 1,
        })),
      ]);
    }
  };

  const getCity = async (id) => {
    const {
      data: { data, code },
    } = await GET_CITY({
      code: id,
    });
    if (code == 200) {
      let addressArr = [...address];
      let ind = addressArr.findIndex((item) => item.value == id);
      if (ind >= 0) {
        addressArr[ind].children = [
          ...data.map((item) => ({
            label: item.name,
            value: item.code,
            code: item.code,
            isLeaf: false,
            type: 2,
          })),
        ];

        setAddress([...addressArr]);
      }

      return data;
    }
  };

  const getArea = async (id, pId) => {
    const {
      data: { data, code },
    } = await GET_CITY({
      code: id,
    });
    if (code == 200) {
      if (pId) {
        let addressArr = [...address];
        let pind = addressArr.findIndex((item) => item.value == pId);
        let cind = addressArr[pind].children.findIndex(
          (item) => item.value == id
        );
        if (pind >= 0) {
          addressArr[pind].children[cind].children = [
            ...data.map((item) => ({
              label: item.name,
              value: item.code,
              code: item.code,
              type: 3,
            })),
          ];

          setAddress([...addressArr]);
        }
      }

      return data;
    }
  };

  // 获取用户可用优惠券列表
  const useCouponList = async () => {
    const price =
      curProductSku.event_price && curProductSku.event_price > 0
        ? (Number(curProductSku.event_price) + postagePrice) * number
        : (Number(curProductSku.sale_price) + postagePrice) * number;
    console.log(price);
    const {
      data: { data, code, msg },
    } = await GET_USER_USE_COUPON_LIST({
      category_id: detail.category_id,
      goods_id: detail.id,
      price: price,
    });

    if (200 === code) {
      setCouponList(
        data.resource.map((item) => ({
          ...item,
          coupon_info: JSON.parse(item.coupon_info),
        }))
      );
      setChooseCoupon(undefined);
    }
  };

  // 创建订单
  const order = async () => {
    setCanToOrder(true);
    console.log(subParams);
    subParams.goods = JSON.stringify([{ ...detail }]);
    subParams.goods_sku = JSON.stringify([
      { ...curProductSku, number: number },
    ]);

    const {
      data: { code, data, msg },
    } = await CREATE_GOODS_ORDER({
      ...subParams,
      useIntegral: useIntegral,
      useCouponId: chooseCoupon?.id,
    });

    if (200 == code) {
      setQrcodeVisible(true);
      setQrcodeUrl(data?.code_url);
    } else {
      message.error(msg);
    }
    setCanToOrder(false);
  };

  // 关闭扫码框
  const closeQrcode = () => {
    setQrcodeVisible(false);
    history.push("/usercenter/order/list");
  };

  useEffect(() => {
    // 获取选择产品id
    if (detail?.id) {
      detail.sku.map((item, index) => {
        let sku = (item?.sku && JSON.parse(item.sku)) || {};
        let skuValue = [];
        for (const key in sku) {
          if (key.indexOf("skuValue") >= 0) {
            skuValue.push(sku[key]);
          }
        }
        if (curSku?.join(",") == skuValue?.join(",")) {
          setCurProductSku(item);
        }
      });
    }
  }, [curSku, showSku]);

  useEffect(() => {
    document.title = "选购 - " + detail.name;
  }, [detail]);

  useEffect(() => {
    // console.log(curProductSku);
    // if (curProductSku?.id) {
    //   setSubParams({
    //     ...subParams,
    //     goods: [{ ...detail }],
    //     goods_sku: [{ ...curProductSku }],
    //   });
    // }
    console.log(detail);
    const postage = (detail.is_free_shipping == 1 && 0) || detail.postage;
    // console.log(postage);

    setPostagePrice(postage);
  }, [curProductSku]);

  useEffect(() => {
    // console.log("subParams", subParams);
  }, [subParams]);

  useEffect(() => {
    useCouponList();
  }, [number]);

  // console.log(postagePrice)

  return (
    <div className="product buy">
      <div className="goods">
        <div className="cover">
          <img src={detail.cover} />
        </div>
        <div className="info">
          <div className="characteristic">
            <div className="title">{detail.name}</div>
            <div className="intro">{detail.intro}</div>
          </div>
          <div className="sku-context">
            {showSku.length &&
              showSku.map((item, index) => (
                <div className="sku" key={index}>
                  <div className="label">{item.label}</div>
                  <div className="value">
                    {item.value.length &&
                      item.value.map((it, ind) => (
                        <div
                          className={`item ${
                            (curSku[index] == it && "cur") || ""
                          }`}
                          key={ind}
                          onClick={() => {
                            let cur = [...curSku];
                            cur[index] = it;
                            setCurSku(cur);
                          }}
                        >
                          {it}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
          <div className="toBuy">
            <Button
              className="buy-btn"
              size="large"
              type="primary"
              disabled={curProductSku?.id ? false : true}
              onClick={() => {
                useCouponList();
                setToBuyVisible(true);
              }}
            >
              立即下单
            </Button>
          </div>
        </div>
      </div>

      {(toBuyVisible && (
        <Modal
          open={toBuyVisible}
          onCancel={() => {
            setToBuyVisible(false);
            setChooseCoupon(undefined);
            setUseIntegral(0);
          }}
          width={600}
          maskClosable={false}
          footer={null}
        >
          <div className="buy-modal-content">
            <div className="title">收货信息单</div>
            <div className="contact">
              <div className="contact-item">
                <div className="label">收货地址</div>
                <div className="value">
                  <div className="item cascader">
                    <Cascader
                      placement={"topLeft"}
                      options={address}
                      loadData={loadAddressData}
                      onChange={onAddressChange}
                      changeOnSelect
                      placeholder="请选择收货区域"
                      size="large"
                    />
                  </div>
                  <div className="item address">
                    <Input
                      size="large"
                      placeholder="请输入详细收货地址"
                      onChange={(e) => {
                        setSubParams({
                          ...subParams,
                          address: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="contact-item">
                <div className="label">收货人姓名</div>
                <div className="value">
                  <div className="item name">
                    <Input
                      size="large"
                      onChange={(e) => {
                        setSubParams({
                          ...subParams,
                          contact_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="contact-item">
                <div className="label">联系电话</div>
                <div className="value">
                  <div className="item mobile">
                    <Input
                      size="large"
                      maxLength={11}
                      onChange={(e) => {
                        setSubParams({
                          ...subParams,
                          contact_mobile: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="contact-item">
                <div className="label">购买数量</div>
                <div className="value">
                  <div className="item mobile">
                    <InputNumber
                      size="large"
                      maxLength={11}
                      min={1}
                      value={number}
                      onChange={(val) => {
                        setNumber(val);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="coupon">
              {/* <div className="coupon-item">
                <div className="label"></div>
                <div className="value">
                  <span className="mr-3">账户共 {userInfo.integral} 积分</span>
                  <InputNumber
                    placeholder="不使用积分"
                    max={userInfo.integral}
                    min={100}
                    step={1}
                    precision={0}
                    style={{ width: 150 }}
                    onChange={(val) => setUseIntegral(val)}
                  />
                </div>
              </div> */}
              <div className="coupon-item">
                <div className="label"></div>
                <div className="value">
                  <Select
                    allowClear
                    value={
                      (chooseCoupon?.coupon_info &&
                        `${chooseCoupon?.coupon_info?.title}/满${Number(
                          chooseCoupon?.coupon_info?.threshold_amount
                        )}减${Number(chooseCoupon?.coupon_info?.amount)}`) ||
                      undefined
                    }
                    onChange={(val) => {
                      const info = couponList.find((item) => item.id == val);
                      setChooseCoupon({
                        ...info,
                      });
                    }}
                    options={[
                      ...couponList.map((item) => ({
                        label: `${item.coupon_info.title}/满${Number(
                          item.coupon_info.threshold_amount
                        )}减${Number(item.coupon_info.amount)}`,
                        value: item.id,
                      })),
                    ]}
                    placeholder="不使用优惠券"
                    style={{ width: 350 }}
                  />
                </div>
              </div>
              <div className="coupon-item">
                <div className="label"></div>
                <div className="value">
                  <span>优惠金额：</span>
                  <span>
                    ￥
                    {(
                      0 +
                      useIntegral / 100 +
                      ((chooseCoupon?.coupon_info?.amount &&
                        Number(chooseCoupon?.coupon_info?.amount)) ||
                        0)
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            <div className="buy">
              <div className="info">
                <div className="total">
                  {curProductSku?.id && (
                    <div className="amount-box">
                      <div
                        style={{
                          display:
                            curProductSku?.event_price &&
                            curProductSku?.event_price > 0
                              ? "none"
                              : "block",
                        }}
                      >
                        <div className="sale">
                          ￥
                          {(
                            ((Number(curProductSku.sale_price) + postagePrice) *
                              number -
                              (0 + useIntegral / 100) -
                              ((chooseCoupon?.coupon_info?.amount &&
                                Number(chooseCoupon?.coupon_info?.amount)) ||
                                0) <=
                              0 &&
                              0.01) ||
                            (Number(curProductSku.sale_price) + postagePrice) *
                              number -
                              (0 + useIntegral / 100) -
                              ((chooseCoupon?.coupon_info?.amount &&
                                Number(chooseCoupon?.coupon_info?.amount)) ||
                                0)
                          ).toFixed(2)}
                        </div>
                        <div className="current">
                          ￥
                          {(
                            (Number(curProductSku.current_price) +
                              postagePrice) *
                            number
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div
                        style={{
                          display:
                            curProductSku?.event_price &&
                            curProductSku?.event_price > 0
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="sale">
                          ￥
                          {(
                            ((Number(curProductSku.event_price) +
                              postagePrice) *
                              number -
                              (0 + useIntegral / 100) -
                              ((chooseCoupon?.coupon_info?.amount &&
                                Number(chooseCoupon?.coupon_info?.amount)) ||
                                0) <=
                              0 &&
                              0.01) ||
                            (Number(curProductSku.event_price) + postagePrice) *
                              number -
                              (0 + useIntegral / 100) -
                              ((chooseCoupon?.coupon_info?.amount &&
                                Number(chooseCoupon?.coupon_info?.amount)) ||
                                0)
                          ).toFixed(2)}
                        </div>
                        <div className="current">
                          ￥
                          {(
                            (Number(curProductSku.sale_price) + postagePrice) *
                            number
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="btn">
                  <Button
                    onClick={() => {
                      order();
                    }}
                    type="primary"
                    size="large"
                    disabled={
                      curProductSku?.id &&
                      subParams?.areas_name &&
                      subParams?.address &&
                      subParams?.contact_name &&
                      subParams?.contact_mobile &&
                      !canToOrder
                        ? false
                        : true
                    }
                  >
                    下单
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )) ||
        ""}
      {(qrcodeVisible && (
        <Modal
          open={qrcodeVisible}
          width={600}
          closable={false}
          footer={[
            <div
              className="price-info text-left"
              style={{
                position: "absolute",
                left: "15px",
                marginTop: "0px",
              }}
            >
              <div
                style={{
                  display:
                    curProductSku?.event_price && curProductSku?.event_price > 0
                      ? "none"
                      : "block",
                }}
              >
                <span className="label">实付金额：</span>
                <span
                  className="price"
                  style={{
                    fontSize: 20,
                    fontWeight: "bolder",
                    marginRight: "15px",
                  }}
                >
                  ￥
                  {(
                    ((Number(curProductSku.sale_price) + postagePrice) *
                      number -
                      (0 + useIntegral / 100) -
                      ((chooseCoupon?.coupon_info?.amount &&
                        Number(chooseCoupon?.coupon_info?.amount)) ||
                        0) <=
                      0 &&
                      0.01) ||
                    (Number(curProductSku.sale_price) + postagePrice) * number -
                      (0 + useIntegral / 100) -
                      ((chooseCoupon?.coupon_info?.amount &&
                        Number(chooseCoupon?.coupon_info?.amount)) ||
                        0)
                  ).toFixed(2) || "0.01"}
                </span>
                <span
                  className="current"
                  style={{
                    fontSize: 12,
                    color: "#999",
                    textDecoration: "line-through",
                  }}
                >
                  ￥
                  {(
                    (Number(curProductSku.event_price) + postagePrice) *
                    number
                  ).toFixed(2) || "0.01"}
                </span>
              </div>
              <div
                style={{
                  display:
                    curProductSku?.event_price && curProductSku?.event_price > 0
                      ? "block"
                      : "none",
                }}
              >
                <span className="label">实付金额：</span>
                <span
                  className="price"
                  style={{
                    fontSize: 20,
                    fontWeight: "bolder",
                    marginRight: "15px",
                  }}
                >
                  ￥
                  {(
                    ((Number(curProductSku.event_price) + postagePrice) *
                      number -
                      (0 + useIntegral / 100) -
                      ((chooseCoupon?.coupon_info?.amount &&
                        Number(chooseCoupon?.coupon_info?.amount)) ||
                        0) <=
                      0 &&
                      0.01) ||
                    (Number(curProductSku.event_price) + postagePrice) *
                      number -
                      (0 + useIntegral / 100) -
                      ((chooseCoupon?.coupon_info?.amount &&
                        Number(chooseCoupon?.coupon_info?.amount)) ||
                        0)
                  ).toFixed(2) || "0.01"}
                </span>
                <span
                  className="current"
                  style={{
                    fontSize: 12,
                    color: "#999",
                    textDecoration: "line-through",
                  }}
                >
                  ￥
                  {(
                    (Number(curProductSku.sale_price) + postagePrice) *
                    number
                  ).toFixed(2) || "0.01"}
                </span>
              </div>
            </div>,
            <Button
              onClick={() => {
                closeQrcode();
              }}
            >
              未完成支付
            </Button>,
            <Button
              type="primary"
              onClick={() => {
                closeQrcode();
              }}
            >
              已完成支付
            </Button>,
          ]}
        >
          <div className="tips mb-5">
            请确认您购买授权后将不支持退款，支付后相关款项会从微信中扣除，如果您不确定是否有强烈的需求，请您先体验30天后转为正式授权即可。
          </div>
          <div className="text-center">
            <QRCodeCanvas width={300} value={qrcodeUrl} />
          </div>
        </Modal>
      )) ||
        ""}
    </div>
  );
};

export default Detail;
