import React, { useEffect, useState, useRef } from "react";
import {
  Switch,
  Route,
  Redirect,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  Spin,
  Layout,
  Card,
  Menu,
  Button,
  Dropdown,
  Checkbox,
  Row,
  Col,
} from "antd";
import {
  ShoppingCartOutlined,
  UserOutlined,
  CloseOutlined,
  DeleteFilled,
  RightOutlined,
} from "@ant-design/icons";

import { GET_INFO, REFRESH_TOKEN, LOGIN_OUT } from "../../api/login";

import {
  GET_GOODS_CATEGORY_LIST,
  GET_GOODS_CATEGORY_DETAIL,
} from "../../api/goodsCategory";

import cookie from "react-cookies";
// import dotenv from "dotenv";
import "./index.less";

//公共函数
import utils from "../../assets/js/public";
import { array2treeMenu } from "../../common/utils";
import basicRouters from "../../assets/json/basicRouters.json";

//页面引入
import Home from "./home/index"; //首页
import Product from "./product/index"; //产品
import ProductDetail from "./product/detail"; //产品详情
import ProductBuy from "./product/buy"; //产品购买
import Os from "./os"; //操作系统
import Solution from "./solution"; //解决方案
import Channel from "./channel/index"; //销售渠道
import Coupon from "./coupon"; //领券中心
import Price from "./price/index"; //价格
import Download from "./download/index"; //下载
import Version from "./download/version"; //版本下载
import Active from "./active"; //活动页
import Event from "./event/event"; //活动页2
import D2191m from "./event/d2191m"; // 活动页3

import UserCenter from "../usercenter"; // 用户中心

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

require("dotenv").config();

const Front = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [shopInfo, setShopInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});

  const [openKeys, setOpenKeys] = useState("home");
  const [curChildKey, setCurChildKey] = useState("");
  const [childMenuVisible, setChildMenuVisible] = useState(false);
  const [menuChildItems, setMenuChildItems] = useState([]);

  const userItems = [
    {
      type: "group",
      key: "name",
      label: (userInfo && userInfo.nickname) || "-",
      children: [
        {
          key: "loginout",
          label: (
            <Button
              type="link"
              className="p-0 text-gray"
              onClick={() => {
                localStorage.removeItem("userInfo");
                cookie.remove("accessToken");
                cookie.remove("accessToken", { path: "/" });
                cookie.remove("accessToken", { path: "*" });
                cookie.remove("userInfo");
                cookie.remove("userInfo", { path: "/" });
                cookie.remove("userInfo", { path: "*" });
                window.location.reload();
              }}
            >
              退出
            </Button>
          ),
        },
      ],
    },
  ];

  const childMenuRef = useRef(null);
  const [menuItems, setMenuItems] = useState([
    {
      label: <Link to="/home">首页</Link>,
      key: "home",
    },
    {
      label: "产品",
      key: "product",
      children: [],
      popupClassName: "product",
    },
    {
      label: <Link to="/solutions">解决方案</Link>,
      key: "solutions",
    },
    // {
    //   label: <Link to="/solution">解决方案</Link>,
    //   key: "solution",
    //   children: [
    //     {
    //       label: "办公协同",
    //       key: "OfficeCollaboration",
    //       children: [],
    //     },
    //     {
    //       label: "教育科研",
    //       key: "EducationAndResearch",
    //       children: [],
    //     },
    //     {
    //       label: "灾难备份",
    //       key: "Disaster Backup",
    //       children: [],
    //     },
    //     {
    //       label: "网络监控",
    //       key: "NetworkMonitoring",
    //       children: [],
    //     },
    //     {
    //       label: "安全审计",
    //       key: "SecurityAudit",
    //       children: [],
    //     },
    //   ],
    // },
    // {
    //   label: <Link to="/channel">销售渠道</Link>,
    //   key: "channel",
    // },
    {
      label: <Link to="/price">价格</Link>,
      key: "price",
    },
    {
      label: <Link to="/download">下载</Link>,
      key: "download",
    },
    {
      label: "帮助与支持",
      key: "help",
    },
    // {
    //   label: <Link to="/coupon">领券中心</Link>,
    //   key: "coupon",
    // },
  ]);

  const plainOptions = ["Apple", "Pear", "Orange"];
  const defaultCheckedList = ["Apple", "Orange"];

  const [checkedList, setCheckedList] = useState([...defaultCheckedList]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const isLogin = async () => {
    if (!cookie.load("accessToken")) {
      cookie.remove("accessToken", { path: "/" });
      setUserInfo({});
      localStorage.removeItem("userInfo");
      return true;
    }
    return false;
  };

  const getUserInfo = async () => {
    const {
      data: { data, code, msg },
    } = await GET_INFO();
    if (code == 200) {
      // console.log(res.data)
      setUserInfo(data);
      localStorage.setItem("userInfo", JSON.stringify(data));
    } else {
      cookie.remove("accessToken", { path: "/" });
      setUserInfo({});
      localStorage.removeItem("userInfo");
    }
  };

  // 获取商品分类
  const getGoodsCategory = async () => {
    const {
      data: { data, code, msg },
    } = await GET_GOODS_CATEGORY_LIST();
    if (code == 200) {
      let menus = [...menuItems];
      let proIndex = menus.findIndex((item) => item.key == "product");
      // console.log(array2treeMenu([...data.resource], "id", "category_id"));
      menus[proIndex].children = array2treeMenu(
        [...data.resource],
        "id",
        "category_id"
      );
      setMenuItems([...menus]);
    }
  };

  useEffect(async () => {
    await isLogin();
    await getUserInfo();
    await getGoodsCategory();

    console.log(location);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        childMenuRef.current &&
        !childMenuRef.current.contains(event.target)
      ) {
        setChildMenuVisible(false);
        setOpenKeys("");
        setMenuChildItems([]);
      } else {
        setChildMenuVisible(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);
  useEffect(() => {
    // console.log(menuItems);
  }, [menuItems]);

  useEffect(() => {
    setOpenKeys(location.pathname.split("/")[1]);
  }, []);

  return (
    <Spin spinning={loading}>
      <Layout className={`${location.pathname !== "/d2191m" ? "" : "d2191m"}`}>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ padding: 0, background: "#fff" }}
          >
            <div className="d-flex justify-content-between align-item-center px-3">
              <div className="left">
                <div className="d-flex align-item-center">
                  <div
                    className="logo mr-3"
                    style={{ textAlign: "center", marginTop: "-3px" }}
                  >
                    <a href="/">
                      <img
                        src={require("../../assets/image/logo1.png").default}
                      />
                    </a>
                  </div>
                  <div className="navs" style={{ width: "800px" }}>
                    <div className="menu">
                      {menuItems.map((item, index) => (
                        <div
                          key={index}
                          className={`item ${
                            (item.key == openKeys && "cur") || ""
                          }`}
                          onClick={() => {
                            setOpenKeys(item.key);
                            if (
                              item.key == "product" ||
                              item.key == "solution"
                            ) {
                              setChildMenuVisible(!childMenuVisible);
                              const curIndex = menuItems.findIndex(
                                (citem) => citem.key == item.key
                              );
                              // console.log(curIndex, menuItems[curIndex]);
                              setCurChildKey(
                                menuItems[curIndex]?.children[0].label
                              );
                              setMenuChildItems(
                                menuItems[curIndex]?.children[0].children
                              );
                            }
                          }}
                        >
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className={`childrenMenu product`}
                    ref={childMenuRef}
                    style={{
                      display: (childMenuVisible && "flex") || "none",
                    }}
                  >
                    <div className="list">
                      {(menuItems[
                        menuItems.findIndex((item) => item.key == openKeys)
                      ]?.children &&
                        menuItems[
                          menuItems.findIndex((item) => item.key == openKeys)
                        ]?.children.map((item, index) => (
                          <div
                            key={index}
                            className={`item ${
                              (item.label == curChildKey && "on") || ""
                            }`}
                            onClick={() => {
                              setCurChildKey(item.label);
                              setMenuChildItems(item.children);
                            }}
                          >
                            <span>{item.label}</span>
                            <span>
                              <RightOutlined />
                            </span>
                          </div>
                        ))) ||
                        ""}
                    </div>
                    <div className="children">
                      {menuChildItems &&
                        menuChildItems.map((item, index) => (
                          <div className="item" key={index}>
                            <div className="title">{item.label}</div>
                            <div className="c-children">
                              {item?.goods &&
                                item.goods.map((item, index) => (
                                  <Link
                                    className="c-item"
                                    to={`/product/detail/${item.id}`}
                                    key={index}
                                    onClick={() => {
                                      setChildMenuVisible(false);
                                    }}
                                  >
                                    {item.name}
                                  </Link>
                                ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="right d-flex align-item-center">
                <div className="items cart mr-3">
                  {/* <div
                    className="icon cart-icon"
                    style={{ cursor: "pointer", fontSize: 22 }}
                  >
                    <ShoppingCartOutlined />
                  </div> */}
                  <div className="cart-context">
                    {/* <div className="cart-head">
                      <div className="actions">
                        <Button type="link" size="small">
                          编辑
                        </Button>
                      </div>
                      <div className="close">
                        <CloseOutlined />
                      </div>
                    </div> */}
                    <div className="cart-body">
                      <Checkbox.Group value={checkedList} onChange={onChange}>
                        {plainOptions.map((item, index) => (
                          <div className="cart-item" key={index}>
                            <div className="actions">
                              <Checkbox value={item} />
                            </div>
                            <div className="cover">
                              <img
                                src={
                                  require("../../assets/image/icon (3).png")
                                    .default
                                }
                              />
                            </div>
                            <div className="title">
                              <div className="name">
                                商品名称商品名称商品名称商品名称商品名称商品名称商品名称
                              </div>
                              <div className="sku">
                                白色, 全高白色, 全高白色, 全高白色, 全高白色,
                                全高白色, 全高白色, 全高
                              </div>
                              <div className="num">x 2</div>
                            </div>
                            <div className="subtotal">￥200000.40</div>
                            <div className="del">
                              <DeleteFilled />
                            </div>
                          </div>
                        ))}
                      </Checkbox.Group>
                    </div>
                    <div className="cart-foot">
                      <div className="allChoose">
                        <Checkbox
                          indeterminate={indeterminate}
                          onChange={onCheckAllChange}
                          checked={checkAll}
                        >
                          全选
                        </Checkbox>
                      </div>
                      <div className="settlement">
                        <div className="total">
                          <div className="text">
                            合计：
                            <span className="amount">￥9999999999.00</span>
                          </div>
                        </div>
                        <div className="btn">
                          <Button type="primary">结算</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items">
                  {(userInfo?.id && (
                    <Dropdown
                      trigger="hover"
                      menu={{
                        items: userItems,
                      }}
                    >
                      <div
                        onClick={() => {
                          history.push("/usercenter/overview");
                        }}
                        style={{ cursor: "pointer", fontSize: 22 }}
                      >
                        <UserOutlined className="mr-1" />
                      </div>
                    </Dropdown>
                  )) || (
                    <div className="links">
                      {/* <a
                        className="link"
                        href="https://bbs.qiduo.net"
                        target="_blank"
                      >
                        用户社区
                      </a> */}
                      {/* {console.log(process.env.REACT_APP_PASSPORT_URL)} */}
                      <div
                        className="link"
                        onClick={() => {
                          window.location.href = `${process.env.REACT_APP_PASSPORT_URL}?appid=${process.env.REACT_APP_PASSPORT_APPID}&redirect_url=${window.location.origin}/check`;
                          // history.push("/login");
                        }}
                      >
                        登录
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Header>
          <Content>
            <Switch>
              {/* 首页 */}
              <Route path="/home" exact component={Home} />
              {/* 产品 */}
              <Route path="/product" exact component={Product} />
              {/* 产品详情 */}
              <Route
                path="/product/detail/:id"
                exact
                component={ProductDetail}
              />
              {/* 产品购买页 */}
              <Route path="/product/buy/:id" exact component={ProductBuy} />
              {/* 解决方案 */}
              <Route path="/solutions" exact component={Solution} />

              {/* 销售渠道 */}
              <Route path="/channel" exact component={Channel} />

              {/* 领券中心 */}
              <Route path="/coupon" exact component={Coupon} />

              {/* 价格 */}
              <Route path="/price" exact component={Price} />

              {/* 下载 */}
              {/* <Route path="/download" exact component={Download} /> */}
              <Route path="/download" exact component={Version} />
              {/* <Route path="/version" exact component={Version} /> */}

              {/* 操作系统 */}
              <Route path="/os" exact component={Os} />
              {/* 活动单页 */}
              <Route path="/active" exact component={Active} />
              <Route path="/event" exact component={Event} />
              <Route path="/d2191m" exact component={D2191m} />
              {/* 用户中心 */}
              <Route path="/usercenter" component={UserCenter} />
              <Redirect to={`/home`} />
            </Switch>
          </Content>
          <Footer>
            {/* */}
            <div className="container">
              <Row className="nav-list" gutter={30}>
                <Col className="nav-item" span={4}>
                  <div className="title">购买产品</div>
                  <div className="label">
                    <a href="#" target="_blank">
                      商城
                    </a>
                  </div>
                </Col>
                <Col className="nav-item" span={4}>
                  <div className="title">下载中心</div>
                  <div className="label">
                    <a href="#" target="_blank">
                      引导下载
                    </a>
                  </div>
                  <div className="label">
                    <a href="#" target="_blank">
                      镜像下载
                    </a>
                  </div>
                  <div className="label">
                    <a href="#" target="_blank">
                      移动端
                    </a>
                  </div>
                  <div className="label">
                    <a href="#" target="_blank">
                      桌面端
                    </a>
                  </div>
                  <div className="label">
                    <a href="#" target="_blank">
                      电视端
                    </a>
                  </div>
                </Col>
                <Col className="nav-item" span={4}>
                  <div className="title">帮助中心</div>
                  <div className="label">
                    <a href="#" target="_blank">
                      使用教程
                    </a>
                  </div>
                  <div className="label">
                    <a href="#" target="_blank">
                      售后服务
                    </a>
                  </div>
                  <div className="label">
                    <a href="#" target="_blank">
                      问题反馈
                    </a>
                  </div>
                </Col>
                <Col className="nav-item" span={4}>
                  <div className="title">我们的公司</div>
                  {/* <div className="label">
                    <a href="#" target="_blank">
                      商城
                    </a>
                  </div> */}
                </Col>
                <Col className="nav-item" span={8}>
                  <div className="title">购买产品</div>
                  <div className="qr-list">
                    {/* <div className="qrcode-list-item">
              <img
                src={require("../../../assets/image/contact_me_qr.png").default}
              />
              <div className="txt">微信扫码加入群聊</div>
            </div> */}
                    <div className="item">
                      <img
                        className="img"
                        src={require("../../assets/image/qr1.png").default}
                      />
                      <div className="title">微信联系我</div>
                    </div>
                    <div className="item">
                      <img
                        className="img"
                        src={
                          require("../../assets/image/contact_me_qr.png")
                            .default
                        }
                      />
                      <div className="title">微信扫码加入群聊</div>
                    </div>
                    <div className="item">
                      <img
                        className="img"
                        src={require("../../assets/image/qr2.png").default}
                      />
                      <div className="title">微信公众号</div>
                    </div>
                    <div className="item">
                      <img
                        className="img"
                        src={require("../../assets/image/qr3.png").default}
                      />
                      <div className="title">哔哩哔哩</div>
                    </div>
                    <div className="item">
                      <img
                        className="img"
                        src={require("../../assets/image/qr4.png").default}
                      />
                      <div className="title">官方QQ群</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="copyright pb-2">
              Copyright @ 2024 七朵云 版权所有
              <a
                className="mx-2"
                href="https://beian.miit.gov.cn/"
                target="_blank"
              >
                沪ICP备2020033741号-1
              </a>
              <a
                className="mx-2"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011302002294"
                target="_blank"
              >
                沪公安网安备31011302002294号
              </a>
            </div>
            <div className="addon text-center">
              互联网数据中心业务/互联网接入服务/国内互联网虚拟专用网/固定网国内数据传送业务/内容分发网络许可证：A2.B1-20242834
            </div>
          </Footer>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default Front;
